define('scientia-resourcebooker/adapters/resource-type', ['exports', 'scientia-resourcebooker/adapters/application', 'scientia-resourcebooker/config/environment', 'ember'], function (exports, _scientiaResourcebookerAdaptersApplication, _scientiaResourcebookerConfigEnvironment, _ember) {
  exports['default'] = _scientiaResourcebookerAdaptersApplication['default'].extend({
    router: _ember['default'].inject.service(),

    urlForFindRecord: function urlForFindRecord() /*id, modelName, snapshot*/{
      var url = this._super.apply(this, arguments);

      // Append adminPermisionsOnly query param when fetching resource-type from a resource calendar
      if (this.get('router.currentRouteName') === 'manage-resources.resource.show') {
        url += '?adminPermissionsOnly=true';
      }
      return url;
    },

    urlForQueryRecord: function urlForQueryRecord(query /*, modelName*/) {

      if (!query || !query.id) return this._super.apply(this, arguments);

      var url = undefined;
      // Append adminPermissionsOnly to queryParams
      if (query.id) {
        url = this.buildURL('resource-type', query.id, null, 'findRecord', query);
        delete query.id;
      }

      return url;
    },

    urlForQuery: function urlForQuery(query) {
      var url = this._super.apply(this, arguments);

      if (query.atResourceRestrictions) {
        url = url.replace('ResourceTypes', _scientiaResourcebookerConfigEnvironment['default'].API.SEARCH.RESOURCE_TYPE);
        delete query.atResourceRestrictions;
      }

      return url;
    }
  });
});